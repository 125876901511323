import React from 'react';
import Layout from '../components/layout';
import Container from '../components/Container';
import PageHeader from '../components/PageHeader';
import { Link } from 'gatsby';
import Footer from '../components/Footer';

export default ()=>{
  return(
    <Layout>
      <PageHeader>
        <h2 className="text-6xl">Aviso de Privacidad</h2>
        <p className="mt-2 text-sm">
          <Link to="/" className="underline">Inicio</Link>
          <i className="fas fa-chevron-right mx-2"></i>
          <Link to="/terminos-politicas" className="underline">Términos y Políticas</Link>
        </p>
        {/* <p className="my-4 max-w-xl mx-auto opacity-75 text-sm">{category.description}</p> */}
      </PageHeader>
      <Container>
        <div className="my-6 text-content">
          <h2>Política de calidad</h2>
          <p>El laboratorio DIAGNOSUR brinda el servicio de análisis clínicos con  experiencia y disciplina en el área, con personal calificado, así como con equipos y tecnología de punta. Con ello buscamos satisfacer las necesidades de los clientes y estamos comprometidos  con los mismos a brindar rapidez, veracidad y calidad en los resultados; ofreciendo un servicio seguro que coadyuve en el diagnóstico, monitoreo y prevención de enfermedades.</p>
          <p>Garantizamos el compromiso con nuestros clientes al establecer controles de calidad en cada uno de los procesos, logrando así eficiencia y eficacia que aseguren la mejora continua de nuestro sistema de gestión de calidad. Dicho sistema opera bajo los lineamientos establecidos en la norma ISO 9001 en su versión 2008 y de la normatividad vigente que hace referencia al establecimiento.</p>
          <h4>Alcance del Sistema de Gestión de Calidad</h4>
          <p>El alcance del sistema de gestión de calidad del laboratorio DIAGNOSUR comprende los siguientes procesos:</p>
          <ul>
            <li>Proceso de Laboratorios de Análisis Clínicos (estudios de análisis de rutina y estudios de análisis clínicos subcontratados)</li>
            <li>Proceso de Compras.</li>
            <li>Proceso contable.</li>
          </ul>

          <h2>Términos aplicables a promociones</h2>
          <p>Los programas de promociones y descuentos son efectivos únicamente en sucursal MATRIZ durante el mes indicado en la publicación de los mismos, se aplican solo en el siguiente horario establecido: de lunes a viernes de 7:00 a 20:00 hrs y sábados de 7:00 a 14:00 hrs. Los programas de promociones y descuentos solo funcionarán con los precios de sucursal MATRIZ y no se aplicarán a urgencias (véase términos aplicables a urgencias). No se aplicará el descuento o promoción marcado en la publicación si ya hubo uno (descuento o promoción) anterior sobre el mismo estudio.</p>
          <p>DIAGNOSUR DIAGNÓSTICOS no se hace responsable por cualquier error en la información que algún miembro del personal de la empresa o persona externa a la misma brinde acerca de los programas de descuentos y promociones, es responsabilidad de quien brinde información errónea responder ante clientes que resulten inconformes por confusiones con respecto a la validez de las promociones (vigencia, sucursal, horario de atención, exclusiones, etc.). Bajo ninguna circunstancia se hará efectiva la promoción toda vez que haya sido comprendida de manera equivocada.</p>
          <p>Los programas de promociones y descuentos son diseñados y dados a conocer de manera mensual por el departamento de publicidad y promoción y es avalado por la dirección general. Los clientes tienen el derecho de conocer los términos aplicables a cada programa de promociones y descuentos; así mismo el personal encargado de brindar información tiene la obligación de mostrar los términos aplicables a cada programa de promociones y descuentos mediante el documento que sustenta la publicación de dicho programa en la sección que ostenta la leyenda “aplica restricciones”. El mencionado documento tiene la función de indicar las promociones que se harán efectivas con las restricciones pertinentes y puede ser utilizado por la empresa DIAGNOSUR DIAGNÓSTICOS S.A. de C.V. para los fines legales que convengan ante las autoridades pertinentes en caso de ser necesario.</p>

          <h2>Términos aplicables a urgencias</h2>
          <p>Se considera urgencias a los servicios que no se brinden dentro del horario de atención establecido en la sucursal MATRIZ, el cual es de lunes a viernes de 7:00 a 20:00 y sábado de 7:00 a 14:00; así como los servicios solicitados en días de suspensión oficial de labores.</p>
          <p>Los precios pueden estar sujetos a cargos extra en el servicio. En caso de que la urgencia implique una toma de muestras a domicilio se requerirá datos acerca del paciente, domicilio y profesional de la salud que solicita la orden de servicio. Para más información acerca del uso que damos a los datos puede revisar nuestro aviso de privacidad.</p>
          <p>La entrega de resultados de los estudios considerados urgencias tendrán lugar en la sucursal MATRIZ. Serán entregados el mismo día para el caso de estudios de rutina; para el caso de estudios que sean pruebas especiales, los resultados se entregarán hasta la fecha que le indiquen según el día y el horario  marcado por el personal encargado de atender al paciente.</p>
          <p>Queda establecido que sucursal MATRIZ es la única sucursal de DIAGNOSUR que tiene un sistema de trabajo de urgencias, dejando excluidas a todas las demás sucursales ya sea laboratorios y/o sucursales de toma de muestras.</p>
          <p>No obstante la exclusión del sistema de trabajo de urgencias de las tomas ajenas a sucursal MATRIZ, las demás sucursales ya sea laboratorios y/o sucursales de toma de muestras, podrán participar del mencionado programa direccionando a sucursal MATRIZ a los pacientes que acudan en un horario que imposibilite la entrega de sus resultados para el mismo día o al siguiente día en caso de ser fin de semana o día de asueto. La toma de la muestra se realizará en la sucursal MATRIZ con carácter de urgencia y el paciente podrá decidir si prefiere recibir ahí mismo sus resultados o en la sucursal que más le convenga (en caso de que los resultados estén para el siguiente día o prefiera no esperarlos).</p>
          <p>Para las demás sucursales ya sea laboratorios y/o sucursales de toma de muestras, el horario de atención será de lunes a viernes de 7:00 a 15:00 y sábado de 7:00 a 14:00, con excepción de la toma de muestras denominada “Hospital General (ubicada en 9 sur oriente #233, Tuxtla Gutiérrez, Chiapas) que cuenta con  horario de atención de 7:00 a 20:00 de lunes a viernes y sábado de 7:00 a 14:00 y la toma denominada “INFONAVIT Grijalva” (ubicada en boulevard Fidel Velázquez, manzana 2, casa 5, col. INFONAVIT Grijalva, segunda sección) que su horario de atención de 7:00 a 18:00 de lunes a viernes y sábado de 7:00 a 14:00. Se reitera: No participan directamente en el sistema de trabajo de urgencias.</p>

          <h2>Política de gestión de quejas y reclamos</h2>
          <p>Se considerará una queja, cualquier comunicación verbal o por escrito por parte de un usuario de alguno de los servicios que ofrece DIAGNOSUR DIAGNÓSTICOS o cualquier persona que actúe en nombre del usuario, que alegue contra errores del servicio, conducta considerada inadecuada por parte del personal; así como negocios, convenios o contratos considerados incumplidos y cualquier otra situación que genere inconformidad que no se haya contemplado en este escrito.</p>
          <p>La queja debe incluir por lo menos la mayoría de los siguientes elementos:</p>
          <ul>
            <li>Nombre del demandante y/o usuario.</li>

            <li>Descripción general de la inconformidad, enfocándose en detalles sobre el objeto de inconformidad, personas involucradas y circunstancias en que tuvo lugar la situación.</li>

            <li>Daños o perjuicios potenciales sufridos por el usuario.</li>

            <li>Fecha en que tuvo lugar el suceso que generó inconformidad y por ende la queja.</li>

            <li>Nombre de la persona(considerado responsable) que es objeto de la queja.</li>
          </ul>
          <p>Ó bien llenar el ormato de quejas y reclamos (solicitarlo en el área correspondiente).  </p>
          <p>Posterior a la presentación de la queja, esta será inmediatamente comunicada a la Dirección General de DIAGNOSUR DIAGNÓSTICOS para darle seguimiento e indagar sobre el problema, la falla, el o los responsables y las circunstancias que llevaron a la situación de inconformidad.</p>
          <h4>Seguimiento y tratamiento de quejas o reclamos</h4>
          <p>Una vez investigados los errores se procederá de la siguiente manera:</p> 
          <p>a) Si se descubre que el objeto de la queja no es responsabilidad del laboratorio, 

          este demostrará el alcance de su responsabilidad en el hecho considerado objeto 

          de la inconformidad, deslindándose así de toda responsabilidad de resarcir 

          cualquier daño relacionado con la queja que el demandante reclame a la empresa.</p>

          <p>b) Si se descubre que el objeto de la queja es responsabilidad de laboratorio, se procederá así:</p>
          
          <ol>
            <li>Será obligatorio ofrecer una disculpa junto con una explicación que sea satisfactoria para el afectado por parte de un representante o directivo de la empresa junto con el directo responsable del error.</li>

            <li>Reparar el daño o perjuicio sufrido por el demandante con relación directa al objeto de la queja.</li>
            <li>Corregir el error o situación objeto de la inconformidad</li>
            <li>Notificar y demostrar el avance y corrección de errores al usuario agraviado e invitarle a no abandonar la relación comercial con la empresa.</li>
          </ol>
          <h4>Errores o fallas sin responsabilidad para la empresa</h4>
          <p>No serán considerados errores o fallas con responsabilidad para la empresa:</p>
          <ul>
            <li>Los inconvenientes técnicos o infraestructurales imposibles de evitar, cualquiera que fuése su naturaleza.</li>
            <li>No se aceptará la responsabilidad por faltas o incumplimientos por parte de la empresa debido a motivos relacionados a desastres naturales.</li>
            <li>No se aceptará la responsabilidad de de objetos de inconformidad que tengan lugar fuera de las instalaciones de DIAGNOSUR DIAGNÓSTICOS o no relacionados con una extensión de su patrimonio.</li>
            <li>Para el caso de pruebas y estudios de laboratorio y ultrasonido, no se aceptará la responsabilidad por objetos de inconformidad en los que el paciente haga caso omiso a las indicaciones previas al estudio y esto afecte los resultados.</li>
            <li>No será aceptada la responsabilidad de cualquier otro suceso o acción cuyo control no dependa directamente de DIAGNOSUR DIAGNÓSTICOS que no hayan sido contemplados en esta política de gestión de quejas y reclamos</li>
          </ul>
        </div>
      
      </Container>
      <Footer />
    </Layout>
  )
}